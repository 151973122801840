if (self == top) {
    document.documentElement.style.display = 'block';
} else {
    document.documentElement.style.display = 'none';
    top.location = self.location;
}




var clearData = function (data) {
    //return DOMPurify.sanitize(data);
    console.log('echeeeeeeeee', data.constructor);
    if ((data && typeof value === 'object' && data.constructor === Array) || (data && typeof data === 'object' && data.constructor === Object)) {
        
        console.log('sdfdsfsdfsdf');
        jQuery(data).each(function (index, _data) {
            
            
            return DOMPurify.sanitize(data);
        });
    } else {
        console.log('sdfdsfsdfsdf aaaaa');
        return DOMPurify.sanitize(data);
    }
};

var clearValue = function (value) {
    
    return DOMPurify.sanitize(value);
    
    if (value instanceof Array) {
        jQuery(value).each(function (index, _data) {
            return clearValue(_data);
        });
    } else {
        
    }

};
/*
 var f = Element.prototype.appendChild;
 Element.prototype.appendChild = function () {
 //console.log(clearData(arguments[0].innerHTML));
 arguments[0].innerText = clearData(arguments[0].innerText);
 return f.apply(this, arguments);
 
 };
*/
/*
var oAppend = jQuery.fn.append;
jQuery.fn.size = function () {
    console.log('dsadasd', arguments);

    // Now go back to jQuery's original size()
    return oAppend.apply(this, arguments);
};
*/

/*
 var SCApp = {};
 
 SCApp.Util = {
 customHandler: function (p) {
 p = [];
 
 console.log('Called from -> custom handler', p);
 
 return p;
 },
 
 validateAjaxResponse: function (data) {
 
 return data.status === "ok" ? true : false;
 },
 
 Ajax: function (options) {
 return t(options).pipe(function (data) {
 
 if (SCApp.Util.validateAjaxResponse(data)) {
 return data;
 } else {
 data = [];
 SCApp.Util.customHandler(data);
 return $.Deferred().promise();
 }
 });
 }
 };
 
 var t = $.ajax;
 
 $.ajax = SCApp.Util.Ajax;
 */